import React from 'react'
import '../styles/Home.css'
import { FaStar, FaFileAlt, FaBookOpen , FaPrint, FaFile} from 'react-icons/fa'; // Import icons
import homeImage from '../assets/home-image.jpeg'
import ok from '../assets/ok.png'
import spar from '../assets/spar.png'
import pnp from '../assets/pnp.png'
import onestop from '../assets/onestop-icon.png'
import metalab from '../assets/metalab.png'
import image1 from '../assets/image1.png'
import image2 from '../assets/image2.png'
import image3 from '../assets/image3.jpeg'
import matchgroup from '../assets/match-group.png'
import print from '../assets/print-ict.png'
import creative from '../assets/creative-art.png'
import start from '../assets/start-ups.png'
import marketing from '../assets/marketing.png'
import operations from '../assets/operations.png'
import paint from '../assets/every-paint.jpeg'
import pen from '../assets/every-pen.png'
import pencil from '../assets/every-pencil.jpeg'
import crayons from '../assets/every-crayons.jpeg'
import colors from '../assets/every-colors.jpeg'
import join from '../assets/join.png'
import school from '../assets/closing-sch.jpeg'
import logos from '../assets/join-logos.png'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useMediaQuery } from 'react-responsive';




function Home() {
  const boxes = [
    { title: 'For Writing', description: 'For all your writing needs, M&G is by your side.', link: '/box1', icon: <FaStar color='purple'/>, color: 'purple'},
    { title: 'For Offices', description: 'For every office need, M&G is your #OneStop Shop.', link: '/box2', icon: <FaFileAlt color='orange'/>, color: 'orange'},
    { title: 'For Creating', description: 'Creativity of the kids to life with M&G.', link: '/box3', icon: <FaBookOpen color='red' />, color: 'red'},
    { title: 'For Printing', description: 'From paper to printer, there is M&G Business Solutions .', link: '/box4', icon: <FaPrint color='blue' />, color: 'blue'},
    { title: 'For Files', description:<><strong>New!</strong>Lets us help you keep your important files.</>, link: '/box5', icon: <FaFile color='black' />, color: 'black'},
  ];
  const images = [
    { title: 'Printing & ICT', image: print },
    { title: 'Creative Art', image: creative },
    { title: 'Startups', image: start },
    { title: 'Marketing', image: marketing },
    { title: 'Operations', image: operations },
    // Add more boxes as needed
  ];

  const topImages = [paint, pen, colors];
  const bottomImages = [pencil, crayons];
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
 

  return (
    <div className='home'>
      <div className='heading'>
        <h1>Write, plan & share.</h1>
        <h1>With M&G by your side.</h1>
        <img src={join} alt='Join Us' className='join-img1' />
        <p>M&G is bringing quality stationery for students and businesses in Zimbabwe.</p>
        <a href='https://wa.me/message/647QZNIYYAVJD1'>
          <button className='home-button'>Contact M&G for Free</button>
        </a>
      </div>
      <div className='home-info'>
        {isSmallScreen ? (
          <Carousel showThumbs={false} showStatus={false} infiniteLoop useKeyboardArrows autoPlay>
            {boxes.map((box, i) => (
              <div className='box' key={i}>
                {box.icon}
                <h2 className='box-heading'>{box.title}</h2>
                <p className='box-description'>{box.description}</p>
                <a href={box.link} style={{color: box.color}} className='box-link'>Learn More</a>
              </div>
            ))}
          </Carousel>
        ) : (
          <div className='box-section'>
            {boxes.map((box, i) => (
              <div className='box' key={i}>
                {box.icon}
                <h2 className='box-heading'>{box.title}</h2>
                <p className='box-description'>{box.description}</p>
                <a href={box.link} style={{color: box.color}} className='box-link'>Learn More</a>
              </div>
            ))}
          </div>
        )}
        <div className='image-container'>
          <img src={homeImage} alt="Home" className='home-image' />
        </div>
      </div>
      <div className='home-content'>
        <h1>Millions Are Writing With M&G Every Day.</h1>
        <p>Powering the best schools, businesses and students in Zimbabwe, <br></br>establish their success.</p>
        <div className='home-content-image-container'>
          <img src={ok} alt="Home" className='content-image' />
          <img src={spar} alt="Home" className='content-image' />
          <img src={pnp} alt="Home" className='content-image' />
        </div>
        <p className='school-names'>
          <span className='school-name'>Mazowe Boys High </span>
          <span className='school-name'>Mutare Boys High</span>
          <span className='school-name'>Quality Academy </span>
          <span className='school-name'>Mutendi High School</span>
          <span className='school-name'>Genesis College</span>
        </p>
      </div>
      <div className='heading-with-icon'>
        <h2>#Stationery-OneStopShop <img src={onestop} alt='home' className='icon' /></h2>
        <h2>Cut costs</h2>
      </div>
      <div className='home-footer'>
        <p>MG Stationery is bring the best price to Zimbabwe.</p>
        <p>That is benefiting everybody.</p>
      </div>
      <div className='metalab'>
        <img src={metalab} alt='Your Image' className='img' />
        <p className='paragraph'><><strong>Tendai Gumbo</strong><br/> Director of Marketing, MetaLab</></p>
      </div>
      <div className='home-bottom-section'>
        <h1>All your writing needs</h1>
        <div className='home-bottom-images'>
          <img src={image1} alt='Your Image 1' className='image-top' />
          <div className='images-bottom'>
            <img src={image2} alt='Your Image 2' className='image-bottom' />
            <img src={image3} alt='Your Image 3' className='image-bottom' />
          </div>
        </div>
        <div className='home-bottom-content'>
          <p>M&G pens are needed on everybody's desk,</p>
          <p>they write well and they also look good too.</p>
        </div>
        <div className='match-group'>
            <img src={matchgroup} alt='Your Image' className='imgg' />
            <p className='paragraph2'><><strong>Tawanda Ngoshi</strong><br/> Director of Sales, Matchgroup</></p>
        </div>
        <div className='every'>
          <h1>Every need, by your side</h1>
          <div className='every-content'>
            <div className='box-section1'>
              {images.map((box, index) => (
                <div key={index} className='box1'>
                  <img src={box.image} alt={box.title} className='box-img'/>
                  <h2>{box.title}</h2>
                </div>
              ))}
              <div className='everypicture'>
                <div className='every-row'>
                  {topImages.map((image, index) => (
                    <img key={index} src={image} alt={`Top Image ${index + 1}`} className='every-img' />
                  ))}
                </div>
                <div className='every-row'>
                  {bottomImages.map((image, index) => (
                    <img key={index} src={image} alt={`Bottom Image ${index + 1}`} className='every-img' />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className='every-closing'>
            <h1>Join a global movement.<br/>Unleash your creativity.</h1>
            <p>MG is an global enterprise with reaches in all five continents and exploring more.<br/>Just us for an exciting career path. </p>
          </div>
        </div>
        <div className='join'>
        <img src={join} alt='Join Us' className='join-img' />
        <div className="rectangle-container">
          <div className="rectangle">
            <h2>1M+</h2>
            <p>community members</p>
          </div>
          <div className="rectangle">
            <h2>150+</h2>
            <p>community groups</p>
          </div>
          <div className="rectangle">
            <h2>50+</h2>
            <p>countries represented</p>
          </div>
        </div>
        <div className="square-container">
          <div className="square">
            <h2>Join us today </h2>
            <p>Always hiring ambitious talents to build this  <br/>community together. </p>
            <img src={logos} alt="Image 1" />
          </div>
          <div className="square">
            <h2>Giving away </h2>
            <p>
              We have charity in mind, and we are<br/> 
              exploring local partners to engage.
              </p>
            <img src={school} alt="Image 2" />
          </div>
        </div>
        <div className='join-contact'>
          <h1>Get in touch for free</h1>
          <p>Write, plan, share, with MG by your side. </p>
          <a href='https://wa.me/message/647QZNIYYAVJD1'>
           <button className='contact-button'>Contact Us</button>
          </a>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
