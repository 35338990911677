import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaHome, FaProductHunt, FaBusinessTime, FaRegAddressCard } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { ListGroup } from 'react-bootstrap';
import Logo from '../assets/mglogo1.png';
import '../styles/Navbar.css';

function Navbar () {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    console.log(isOpen); // Check if state is being updated
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest('.burger-icon')) {
        return;
      }
      setIsOpen(false);
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  

  return (
    <div className='navbar'>
      <div className='leftside'>
        <img src={Logo} alt='Logo'/>
        {isSmallScreen ? (
          <FaBars className="burger-icon" onClick={toggleMenu} style={{cursor: 'pointer'}} />
        ) : (
          <>
            <Link to='/'>Home</Link>
            {/*<Link to='/products'>Products</Link>*/}
            <Link to='/business'>Business Solutions</Link>
            {/*<Link to='/contact'>Resources</Link>*/}
          </>
        )}
      </div>
      <div className='rightside'>
        {!isSmallScreen || !isOpen ? (
          <a href='https://wa.me/message/647QZNIYYAVJD1'>
            <button className='nav-button'>Contact Us</button>
          </a>
        ) : null}
      </div>
      {isSmallScreen && isOpen && (
        <div className="menu-box">
          <ListGroup variant="flush">
            <ListGroup.Item><FaHome /> <Link to='/'>Home</Link></ListGroup.Item>
            {/*<ListGroup.Item><FaProductHunt /> <Link to='/products'>Products</Link></ListGroup.Item>*/}
            <ListGroup.Item><FaBusinessTime /> <Link to='/business'>Business Solutions</Link></ListGroup.Item>
            {/*<ListGroup.Item><FaRegAddressCard /> <Link to='/contact'>Resources</Link></ListGroup.Item>*/}
          </ListGroup>
        </div>
      )}
    </div>
  )
}

export default Navbar;  
