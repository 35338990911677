import React from 'react'
import '../styles/Footer.css'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa';
import { FaFacebook, FaLinkedin, FaTwitter, FaWhatsapp } from 'react-icons/fa';


function Footer() {
  return (
    <div className='footerContainer'>
      <div className='footerLeft'>
        <h3>ABOUT US</h3>
        <p>Your #OneStopShop for all your stationery needs</p>
        <div className="social-icons">
        <a href="https://www.facebook.com/your-page" className='foot-ico' target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
        <a href="https://www.linkedin.com/in/your-profile" className='foot-ico' target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
        <a href="https://twitter.com/your-profile" className='foot-ico' target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
        <a href="https://wa.me/message/647QZNIYYAVJD1" className='foot-ico' target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
        </div>
      </div>
      <div className='footerMiddle'>
        <h3>OUR SERVICES</h3>
        <p>Office Equipment</p>
        <p>All Stationery</p>
        <p>Web based solutions</p>
      </div>
      <div className='footerRight'>
        <h3>CONTACT US</h3>
        <p><FaPhone /> Phone: 263-78-708-7333</p>
        <p><FaEnvelope /> Email: sales@mgzimbabwe.com</p>
        <p><FaLocationArrow /> Location: 19 Greenock Road, Workington, Harare</p>
      </div>
    </div>
  )
}

export default Footer;
