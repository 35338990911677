import React from 'react';
import '../styles/Business.css';
import Printer from '../assets/printer.jpg';
import Paper from '../assets/paper.jpg'
import { FaCode } from 'react-icons/fa';
import geminiLogo from '../assets/Google-Bard.png'; // replace with the path to your Gemini logo
import openaiLogo from '../assets/Openai.png'; // replace with the path to your OpenAI logo


function Business() {
    return (
        <div className="business-container">
            <section className="business-section">
                <h1 className="business-heading">Business Products</h1>
                <p className="business-text">M&G offers a lot products for businesses, all office stationery and equipment. 
                For your office <br/> requirements, M&G Stationery is here for you, printers, bond paper just to mention a few. #OneStopShop</p>
                <div className="business-images">
                    <img src={Printer} alt="Business" />
                    <img src={Paper} alt="Business" />
                </div>
            </section>
            <section className="it-section">
                <h1 className="it-heading">IT and Network Solutions</h1>
                <div className="it-content">
                    <p className="it-text">M&G is here to help you with your Custom solutions, Web development, Chatbots, <br/> AI & Machine Learning, just to mention a few. Let M&G take you digital.#OneStopShop</p>
                    <FaCode size={150} className='it-icon'/>
                </div>
            </section>
            <div className="box-containerr">
                <div className="boxx">
                    <h2 className="box-titlee">Custom Solutions</h2>
                    <p className="box-descriptionn">We are here to create your custom solution and help you grow. We can create all your digital solution.</p>
                </div>
                <div className="boxx">
                    <h2 className="box-titlee">Web Development</h2>
                    <p className="box-descriptionn">We are here to create your website and all web solutions. We are here for you always!</p>
                </div>
                <div className="boxx">
                    <h2 className="box-titlee">AI Chatbots</h2>
                    <p className="box-descriptionn">M&G can help you with a seemless, customer service, efficient and effective customer care. Here to get you to the future!</p>
                </div>
            </div>
            <div className="logo-container">
                <img src={geminiLogo} alt="Gemini" />
                <img src={openaiLogo} alt="OpenAI" />
            </div>
        </div>
    );
};

export default Business;